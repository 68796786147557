import React from 'react';
import PageTitle from '../Shared/PageTitle';

const FamilialLife = () => {
    return (
        <div className='mt-20 md:mt-32 lg:mt-28'>
            <PageTitle title='Familial Life(Marriage and Inheritance)'></PageTitle>
            <h2 className='text-3xl font-bold mt-5'>Familial Life(Marriage and Inheritance)</h2>

        </div>
    );
};

export default FamilialLife;