import { Route, Routes } from 'react-router-dom';
import './App.css';
import Ethics from './Pages/Ethics/Ethics';
import FamilialLife from './Pages/FamilialLife/FamilialLife';
import FollowersOfScientificHumanism from './Pages/FollowersOfScientificHumanism/FollowersOfScientificHumanism';
import Form from './Pages/Form/Form';
import Home from './Pages/Home/Home';
import Login from './Pages/Login/Login';
import RequireAuth from './Pages/Login/RequireAuth';
import SignUp from './Pages/Login/SignUp';
import PersonalLife from './Pages/PersonalLife/PersonalLife';
import Principles from './Pages/Principles/Principles';
import ScientificHumanism from './Pages/ScientificHumanism/ScientificHumanism';
import ScientificHumanisticMethod from './Pages/ScientificHumanisticMethod/ScientificHumanisticMethod';
import Navbar from './Pages/Shared/Navbar';
import NotFound from './Pages/Shared/NotFound';
import GoogleFontLoader from 'react-google-font-loader';

function App() {
  return (
    <div className="App mt-20 md:mt-32 lg:mt-24 px-6">
      <Navbar></Navbar>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/scientific-humanism' element={<ScientificHumanism></ScientificHumanism>}></Route>
        <Route path='/principles' element={<Principles></Principles>}></Route>
        <Route path='/ethics' element={<Ethics></Ethics>}></Route>
        <Route path='/scientific-humanistic-method' element={<ScientificHumanisticMethod></ScientificHumanisticMethod>}></Route>
        <Route path='/followers-of-scientific-humanism' element={<FollowersOfScientificHumanism></FollowersOfScientificHumanism>}></Route>
        <Route path='/personal-life' element={<PersonalLife></PersonalLife>}></Route>
        <Route path='/familial-life' element={<FamilialLife></FamilialLife>}></Route>
        <Route path='/form' element={
          <RequireAuth>
            <Form></Form>
          </RequireAuth>
        }></Route>
        <Route path='/login' element={<Login></Login>}></Route>
        <Route path='/signup' element={<SignUp></SignUp>}></Route>
        <Route path='*' element={<NotFound></NotFound>}></Route>
      </Routes>
      <GoogleFontLoader
        fonts={[
          {
            font: 'Elsie Swash Caps',
            weights: [400, '400i'],
          },
          {
            font: 'Roboto Mono',
            weights: [400, 700],
          },
          {
            font: 'Roboto Slab',
            weights: [400, 700],
          },
        ]}
        subsets={['cyrillic-ext', 'greek']}
      />
    </div>
  );
}

export default App;
