import React from 'react';

const Form = () => {
    return (
        <div>
            <h2 className='text-3xl font-bold mt-5'>Under Development</h2>
        </div>
    );
};

export default Form;