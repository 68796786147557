import React from 'react';
import PageTitle from '../Shared/PageTitle';

const ScientificHumanism = () => {
    return (
        <div>
            <PageTitle title='Scientific Humanism'></PageTitle>
            <h2 className='text-3xl font-bold mt-5'>Scientific Humanism</h2>
        </div>
    );
};

export default ScientificHumanism;