import React from 'react';
import PageTitle from '../Shared/PageTitle';

const FollowersOfScientificHumanism = () => {
    return (
        <div>
            <PageTitle title='Followers of Scientific Humanism'></PageTitle>
            <h2 className='text-3xl font-bold mt-5'>Followers of Scientific Humanism</h2>
        </div>
    );
};

export default FollowersOfScientificHumanism;