import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { signOut } from 'firebase/auth';
import { Link } from 'react-router-dom';
import auth from '../../firebase.init';

const Navbar = () => {
    const [user] = useAuthState(auth);
    const logOut = () => {
        signOut(auth);
        localStorage.removeItem('accessToken');
    }
    const menuItems = <>
        <li className='mt-2'><Link to='/' className='text-lg hover:text-gray-400 duration-500'>Home</Link></li>
        <li className='mt-2'><Link to='/scientific-humanism' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Scientific Humanism</Link></li>
        <li className='mt-2'><Link to='/principles' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Principles</Link></li>
        <li className='mt-2'><Link to='/ethics' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Ethics</Link></li>
        <li className='mt-2'><Link to='/scientific-humanistic-method' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Scientific-Humanistic Method</Link></li>
        <li className='mt-2'><Link to='/followers-of-scientific-humanism' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Followers Of Scientific Humanism</Link></li>
        <li className='mt-2'><Link to='/personal-life' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Personal Life</Link></li>
        <li className='mt-2'><Link to='/familial-life' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Familial Life</Link></li>
        <li className='mt-2 mb-2'><Link to='/form' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7'>Form</Link></li>
        {!user && <li><Link to='/login' className='text-lg'>Login</Link></li>}

    </>
    const [open, setOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(false);




    return (
        <div className='shadow-md w-full fixed top-0 left-0'>
            <div className='lg:flex md:flex md:items-center lg:items-center justify-between bg-white py-4 px-5 md:px-10'>
                <div className='font-bold text-2xl cursor-pointer flex items-center font-[Elsie Swash Caps] text-gray-800 ml-12' style={{
                    fontFamily: 'Elsie Swash Caps'
                }} >
                    <Link to='/' >Scientific Humanity</Link>
                </div>
                <div onClick={() => setOpen(!open)} className='text-3xl absolute  top-4 md:top-6 cursor-pointer '>
                    <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
                </div>

                <div className='flex justify-center items-center right-0 top-4 absolute'>
                    {
                        user &&
                        <>
                            <div className=' top-4 md:hidden lg:hidden '>
                                <div className="avatar online">
                                    <div className="w-8 text-2xl font-bold rounded-full ring ring-green ring-offset-base-100 ring-offset-3">
                                        <img src={user?.photoURL} alt={user?.displayName.split('')[0]} />
                                    </div>
                                </div>
                            </div>
                            <button onClick={logOut} className="btn btn-ghost normal-case text-xl md:hidden lg:hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                </svg>

                            </button>
                        </>
                    }
                </div>
                {/* small screen menu dropdown*/}
                <ul onClick={() => setOpen(false)}
                    style={{
                        fontFamily: 'Roboto Slab'
                    }}
                    className={`md:hidden md:items-center text-left md:pb-0 pb-8 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-16 opacity-100' : 'top-[-510px]'} md:opacity-100 `} >
                    {menuItems}
                </ul>

                {/* large screen menu dropdown display */}
                <ul onClick={() => setOpen(false)}
                    style={{
                        fontFamily: 'Roboto Slab'
                    }}
                    className={`hidden lg:block md:block  md:items-center text-left  pb-8 absolute bg-white md:z-auto z-[-1] left-0 w-96  pl-9 transition-all duration-500 ease-in ${open ? 'top-20 opacity-100' : 'top-[-510px]'} opacity-100 `}>
                    {menuItems}
                </ul>

                {/* large screen */}
                <ul
                    style={{
                        fontFamily: 'Roboto Slab'
                    }}
                    className={`md:flex md:items-center hidden text-left md:pb-0 pb-8 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-16 opacity-100' : 'top-[-510px]'} md:opacity-100 `}>
                    <li><Link to='/scientific-humanism' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7 mr-3'>Scientific Humanism</Link></li>

                    <li onClick={() => setOpenSubMenu(!openSubMenu)} className=' cursor-pointer text-lg mr-2'> Scientific Humanity
                        <ul className={`menu  bg-base-100 ${openSubMenu ? 'top-[80px] absolute z-0' : 'hidden'} w-56`}>
                            <li><Link to='/principles' className='text-lg  duration-500 md:my-0 my-7 mr-3'>Principles</Link></li>
                            <li><Link to='/ethics' className='text-lg  duration-500 md:my-0 my-7 mr-3'>Ethics</Link></li>
                        </ul>
                    </li>
                    <li><Link to='/form' className='text-lg hover:text-gray-400 duration-500 md:my-0 my-7 mr-3'>Form</Link></li>

                    {
                        user &&
                        <>
                            {/* <button className="btn btn-ghost normal-case text-xl">{user?.displayName}</button> */}

                            <div className="avatar online">
                                <div className="w-8 text-2xl font-bold rounded-full ring ring-green ring-offset-base-100 ring-offset-3">
                                    <img src={user?.photoURL} alt={user?.displayName.split('')[0]} />
                                </div>
                            </div>

                            <button onClick={logOut} className="btn btn-ghost normal-case text-lg">Log Out</button>

                        </>
                    }
                    {
                        !user &&
                        <Link to="/login" className="btn btn-ghost normal-case text-lg">Login</Link>
                    }

                </ul>
            </div>
        </div>


    );
};

export default Navbar;