import React from 'react';
import PageTitle from './PageTitle';

const Loading = () => {
    return (
        <div>
            <PageTitle title='Loading...'></PageTitle>
            <div className='h-screen flex justify-center items-center'>
                <div style={{ borderTopColor: 'transparent' }}
                    className="w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin"></div>
            </div>
        </div>
    );
};

export default Loading;